var menu = {

    mobileMenuVisible: false,

    _init: function () {

        this.registerEvents();

    },

    registerEvents: function () {

        var self = this;

        $('a').click(function (event) {

            var href = $(this).attr('href');

            if (href.indexOf('#') > -1 && $(href).length > 0) {

                event.preventDefault();

                $('html, body').animate({
                    scrollTop: $(href).offset().top - 90
                }, 500);

                if ($(window).width() < 1023)
                    self.hideMenu();

            } else
                return true;

        });

        $('#mobile-menu-toggler').click(function () {
            self.toggleMenu();
        });

    },

    toggleMenu: function () {

        if (this.mobileMenuVisible)
            this.hideMenu();
        else
            this.showMenu();

    },

    hideMenu: function () {

        this.mobileMenuVisible = false;
        $('#menu,#lang-switch').fadeOut();

    },

    showMenu: function () {

        this.mobileMenuVisible = true;
        $('#menu,#lang-switch').fadeIn();

    }



}

$(document).ready(function () {

    menu._init();

});